import React from 'react'
import ClientJson from '../Client/OurClients.json'
import '../Client/Client.css'
import { __RouterContext } from 'react-router'

function Client() {
    return (
        <div>
            {/* <Block/> */}
            <div className="container" id={window.location.pathname === "/process" ?"processclient" :"client"}>
                <p className=" uk-margin-large-top title">Our Clients</p>
                <div class="uk-grid-column-small uk-child-width-1-5@l uk-child-width-1-5@s uk-text-center" uk-grid="true">
                    {ClientJson.map(obj => {
                        return (
                            <div className="">
                                <div class="cardos"><img className="margintopex" src={obj.images} alt="" /></div>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </div>
    )
}

export default Client
