import React, { useState, useEffect } from "react";
import "../Model/Model.css";
import { useHistory } from "react-router-dom";

function Model({ url }) {
  const history = useHistory();
  function handleClick() {
    history.push("/");
  }
  console.log(url)
  return (
    <div className="size">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          {/* <button
            onClick={() => {
              closenow(false);
            }}
          >
            X
          </button> */}
        </div>
        <iframe
          src="https://forms.gle/GhJLgr8pbiWXbLwH6"
          width="100%"
          height="882"
          frameborder="0"
          marginheight="0"
          marginwidth="0">
          Loading…
        </iframe>
        <div className="footer">
          <button
            onClick={handleClick}
            id="cancelBtn"
          >
            Cancel
          </button>
          {/* <button>Continue</button> */}
        </div>
      </div>
    </div>
  );
}

export default Model;
