import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios"; 
import Header from "./Components/Navbar/Header";
import Banner from "./Components/Banner/Banner";
import Testi from "./Components/Testimonial/Testi";
import Home from "./Components/Homepage/Home";
import Project from "./Components/Projec/Project";
import Footer from "./Components/Footer/Footer";
import Menu from "./Components/Pages/Menu";
import Startup from "./Components/Pages/Startup";
import Mentorship from "./Components/Pages/Mentorship";
import Company from "./Components/Pages/Company";
import Contact from "./Components/Pages/Contact";
import {
  BrowserRouter as Router,
  Link,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Page8 from "./Components/Pages/Page8";
import Page11 from "./Components/Pages/Page11";
import OurContact from "./Recruiters/OurContact/OurContact";
import Finel from "./Recruiters/FinelOutput/Finel";
import Cover from "./Recruiters/Cover/Cover";
import CoverCopy from "./Components/Banner/CoverCopy";
import RecSteps from "../src/RecSteps/ProccessOfRecruit/RecProcess";
import Head from "./Recruiters/HeadRec/Head";
import RecFooter from "./Recruiters/RecFooter/RecFooter";
import Recruitment from "./MockInterview/Recruitment";
import RecruitmentCopy from "./Components/Banner/RecruitmentCopy";
import ScrollToTop from "./Components/ScrollToTop";
import Closure from "./Components/Closure/Closure";
import List from "../src/Components/List/List";
import Model from "./Components/Model/Model";
import Description from "./Components/Description/Description";
import ListJson from "../src/Components/List/List.json";
import Empanelment from "./Components/Empanelment";
import ListTest from "./Components/List/ListTest";
import User from "./Components/Description/User";
import Artistpage from "../src/Components/ArtistPage/ArtistCoverPage"

function App() {
  const [colour, setColur] = useState("green");
  const [Company, setcompany] = useState();
  // let previousClickedJob =
  // let currentlyClickedJob =

  const dataPass = (jobpost) => {
 var div = document.getElementById("jobMenu" + jobpost.id);
//  div.style.backgroundColor = "green";
    setcompany(jobpost);
  };
  const showDefaultJob = (defaultJobPost) => {
    setcompany(defaultJobPost);
  };
  const [url, setUrl] = useState();
  const urlFetcher = (urlIGot) => {
    // e.preventDefault()
    setUrl(urlIGot);
  };
  console.log(url);
  const history = useHistory();
  let name = "RecruitMagik";
  // axios.get('https://recruitmagikjobs.s3.ap-south-1.amazonaws.com/List.json').then(res=>console.log(res))
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Head />
        <Route path="/" exact>
          <Finel name={name} />
        </Route>
        <Route path="/process">
          <RecSteps />
        </Route>
        <div style={{ display: "flex" }}>
          <Route path="/jobpost">
            <List
            colour={colour}
            setColur={setColur}
              showDefaultJob={showDefaultJob}
              urlFetcher={urlFetcher}
              url={url}
              dataPass={dataPass}
            />
          </Route>
          <div className="vl">
            {/* click job to view the job */}
            {/* {window.innerWidth > 780 &&
              window.location.pathname === "/jobpost" && (
                <Route path="/jobpost">
                  <User urlFetcher={urlFetcher} dataPass={dataPass} />
                </Route>
              )} */}
            <Route path="/jobpost/:titleId">
              <Description urlFetcher={urlFetcher} dataPass={dataPass} />
            </Route>
          </div>
        </div>

        {window.innerWidth < 780 && (
          <Route path="/jobposts/:titleId">
            <Description urlFetcher={urlFetcher} />
          </Route>
        )}
        <Route path="/submit">
          <Model url={url} />
        </Route>
        <Route path="/empanelment">
          <Empanelment />
        </Route>

        <Footer />
        {/* <Route path="/client" >
        <ScrollToTop />
        <Head />
        <Closure />
        <Footer />
      </Route> */}

        {/* <Route path="/startup" >
        <ScrollToTop />
        <Header />
        <Startup />
        <Footer />
      </Route>



      <Route path='/mentorship'>
        <ScrollToTop />
        <Header />
        <Mentorship />
        <Footer />
      </Route>

      <Route path='/company'>
        <ScrollToTop />
        <Header />
        <Contact />
        <Footer />
      </Route>

      <Route path="/project">
        <ScrollToTop />
        <Header />
        <Page8 />
        <Footer />
      </Route>

      <Route path="/page11" >
        <ScrollToTop />
        <Header />
        <Page11 />
        <Footer />
      </Route> */}
      </Router>
    </div>
  );
}

export default App;
