import React from "react";
import Right from "../Assets/Right.svg";
import Left from "../Assets/Left.svg";
import "../TechWeDo/TechWeDO.css";
import ClientJson from "../../Recruiters/Client/Client.json";
function Closure() {
  return (
    <div className="superCon">
      <div className="common_width" style={{paddingRight:"0"}}>
        <p className="headingTech fontColRec">Recent closures </p>
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider=" true"
        >
          <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m uk-grid slidshown">
            {ClientJson.map((obj) => {
              return (
                <li>
                  <div className="boxio">
                    <div class="uk-panel sliderbox">
                      <img src={obj.images} className="headImg" alt="" />
                    </div>
                    <div className="belowbx">
                      <div className="up">
                        <p className="sliderHead">{obj.title}</p>
                      </div>
                      {/* <div className="flexslid">
                        <img className="ig" src={obj.logo} alt="" />
                        <p className="slidertype">{obj.type}</p>
                      </div> */}
                      <p className="dcre">{obj.description}</p>
                      <a className="linkw" href={obj.website}>
                        {obj.website}
                      </a>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          {/* <a
            class="uk-position-center-left uk-position-large "
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          >
            <img src={Left} className="rigt" alt="" />
          </a>
          <a
            class="uk-position-center-right uk-position-small "
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          >
            <img src={Right} className="rigt" alt="" />
          </a> */}
        </div>
        {/* <div>
          <p className="belowtxt">
            We do have contract to hire employees in all the cutting edge
            technologies.
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Closure;
