import React  from 'react';
import { Link, Route } from 'react-router-dom';
import '../Pages/page8.css'
import img1 from '../Assets/img3.PNG'
import img2 from '../Assets/img11.jpg'
import img3 from '../Assets/mem.jpeg'
import img4 from '../Assets/img4.PNG'
import img5 from '../Assets/img5.PNG'
import {useHistory} from 'react-router-dom'
import Page11 from './Page11'


function page8 () {
const history = useHistory
        return(
            <div class="" >
        
           <div class="uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1@s uk-grid"style={{backgroundColor:"black"}} >

         

                    <div class="uk-card  uk-card-body uk-light " >
                    <p class="uk-visible@s uk-align-right" style={{textAlign:"",marginTop:"18%",color:"white",fontSize:"40px",marginLeft:"30%"}}>A good plan today<br/>
                    is better than a <br/>
                    perfect plan tomorrow</p>


                     <p class="uk-hidden@s" style={{textAlign:"left",marginTop:"25%",color:"white",fontSize:"30px",marginLeft:"10%"}}>A good plan today<br/>
                     is better than a <br/>
                     perfect plan tomorrow</p>
                        
                    </div>

              <div class="uk-remove-padding uk-margin-large-top">
            
                      <li><img src={img1}></img></li><br/><br/><br/><br/>
            
              </div>

            </div>

{/* ------------------------------------------------------- */}
{/* <div class="uk-inline">
   <img style={{width:"100%"}} src={img2} /> 
    <div class="uk-overlay uk-position-right" style={{marginTop:"",marginRight:""}}>
        <p style={{color:"white",fontSize:"35px",fontWeight:"700",letterSpacing:"1px"}}>WEVA Prime Talks</p><br/>
        <p style={{color:"white",fontSize:"20px",letterSpacing:"1.3px"}}>Prime talks by Weva reveal little voices and<br/>
         vital ideas.it"s a talk show created from the<br/>
         children,by the children,and for the children.</p><br/><br/><br/><br/><br/>
         <p style={{color:"white",fontSize:"13px",letterSpacing:""}}>Download App</p>
    </div>

</div> */}

<div class="uk-card uk-card-default uk-card-body uk-width-1-2@m " style={{backgroundColor:"white",width:"90%",marginLeft:"5%",marginRight:"5%"}}>

<p style={{fontSize:"50px",color:"black",fontWeight:"500"}}>Project</p>
<br/><br/>

<div class="uk-inline uk-visible@s">
   <img style={{width:"100%",paddingLeft:"20px"}} src={img2} /> 
    <div class="uk-overlay uk-position-right" style={{marginLeft:"2%",marginRight:"2%"}}>
        <p style={{color:"white",fontSize:"35px",fontWeight:"700",letterSpacing:"1px"}}>WEVA Prime Talks</p><br/>
        <p style={{color:"white",fontSize:"20px",letterSpacing:"1.3px"}}>Prime talks by Weva reveal little voices and<br/>
         vital ideas.it"s a talk show created from the<br/>
         children,by the children,and for the children.</p><br/><br/><br/><br/><br/>
         <p style={{color:"white",fontSize:"13px",letterSpacing:""}}>Download App</p>
    </div>
</div>


<div class="uk-hidden@s" style={{textAlign:"center"}}>
<img src={img4}></img>
</div>
<br/><br/><br/>

<div class="uk-visible@s" style={{textAlign:"center"}}>

{/* <div class="uk-card uk-card-secondary uk-card-body">
<img src={img2}></img>
            <h3 class="uk-card-title">Secondary</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div> */}
<br/>
<br/>
{/* <img onClick={()=>history.push('/page11')} src={img3}></img> */}
{/* <Link to='./page11'></Link> */}
<img  src={img3}></img>
{/* <Route component={Page11} path='/page11'/> */}
</div>

<div class="uk-hidden@s" style={{textAlign:"center"}}>
<img style={{marginLeft:"3px"}} src={img3}></img>
</div>

            </div>
            
            {/* <div class="uk-child-width-1-2 uk-grid " style={{backgroundColor:"black"}}>

            <div class="" style={{backgroundColor:"white",paddingRight:"5px"}}>
            <li><img src={img2}></img></li>
            <p class="uk-card-title" style={{fontSize:"40px"}}>WEVA Prime Talks</p>
            <p>Prime talks by Weva reveal little voices and vital<br/>
             ideas.It's  a talk show created from the<br/>
            children.by the children,and for the children</p>
            </div>

            <div class="uk-card uk-card-default uk-card-body uk-width-1-2@m" style={{backgroundColor:"black"}}>
            <p class="uk-card-title" style={{fontSize:"40px"}}>WEVA Prime Talks</p>
            <p>Prime talks by Weva reveal little voices and vital<br/>
             ideas.It's  a talk show created from the<br/>
            children.by the children,and for the children</p>
            </div>
           

           </div> */}




            </div>

        
             );
            }
            
            export default page8;