import React from 'react'
import Head from '../HeadRec/Head'
import Cover from '../Cover/Cover'
import Service from '../Service/Service'
import Block from '../Block/Block'
import Client from '../Client/Client'
import Firm from '../Firm/Firm'
import OurContact from '../OurContact/OurContact'
import Teams from '../Tems/Teams'
import RecFooter from '../RecFooter/RecFooter'
import Footer from '../../Components/Footer/Footer'
import TechWeDo from '../../Components/TechWeDo/TechWeDo'
import Closure from '../../Components/Closure/Closure'
function Finel({name}) {
   
    return (
        <div>
            {/* <Head /> */}
            <Cover name={name} />
            <TechWeDo />
            <Closure/>
            <Service />
            <Block name={name} />
            <Client />
            <Firm name={name} />
            <Teams />
            <OurContact />
            {/* <Footer name={name} /> */}
            {/* <RecFooter/> */}


        </div>
    )
}

export default Finel
