import React from 'react'
import Cover from '../Cover/Cover'
import Titile from '../Service/Service.css'

function Service() {
    return (
        <div>
            {/* <Cover/> */}
            <div className="container">
            <p className=" uk-margin-xlarge-top title">Service Offerings</p>
            <ul className="uk-list uk-list-square ">
                <li className="ulist">
                    <p className="list">Customized end to end Recruitment Solutions</p>
                </li>
                <li className="">
                    <p className="list">Mock interviews on requirements within the affordable rates</p>
                </li>
                <li className="">
                    <p className="list">Mentorship for the candidates who wants to switch their career 
                    to various other technologies</p>
                </li>
                <li className="">
                    <p className="list">Empowering Entrepreneurs from various domains to set up their
                     IT startup by consulting on Technology, Architecture, Recruitment and helping for
                      setting up their own development Team and Infrastructure</p>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default Service
