import React from 'react'
import '../Firm/Firm.css'
import Client from '../Client/Client'
import Team from '../../Components/Assets/Our_Firm_Image.png'

function Firm({name}) {
    return (
        <div>
            {/* <Client/> */}
            <div style={{backgroundColor:"#2D2D2D"}} className="uk-margin-medium-top uk-visible@m">
                <b>.</b>
                <div className=" container ">
                <p className="  block-title uk-margin-medium-top" >Our firm</p>
                <div className="row uk-margin-medium-top ">
                    <div className="col-md-6">
                    <p className="box-text3">{name} is managed by the recruitment experts who has combined 
                    expertise of more than 10 years in Mentorship, IT Technical consulting, Recruitment and placement 
                    and Mock interviews.
                    </p>
                    <p className="box-text3">We follow a process which will make sure that the candidates we present in
                     front of our clients will be screened to a good extend before we present them to our clients.
                    </p>
                    <p className="uk-margin-large-bottom box-text3">Our presence are in Cochin (Head office) and Pan India (Associates).
                    </p>
                    </div>
                    <div className="col-md-6 uk-margin-large-bottom">
                        <img className="uk-margin-medium-left" style={{marginTop:"-47px"}} src={Team} alt="" />
                    </div>
                </div>
                </div>
            </div>

{/* small-screen */}

<div style={{backgroundColor:"#2D2D2D"}} className="uk-margin-xlarge-top uk-hidden@m">
                <b>.</b>
                <div className=" container ">
                <p className="  block-title uk-margin-xlarge-top" >Our firm</p>
                <div className="row uk-margin-medium-top ">
                    <div className="col-md-6">
                    <p className="box-text3">{name} is managed by the recruitment experts who has combined 
                    expertise of more than 10 years in Mentorship, IT Technical consulting, Recruitment and placement 
                    and Mock interviews.
                    </p>
                    <p className="box-text3">We follow a process which will make sure that the candidates we present in
                     front of our clients will be screened to a good extend before we present them to our clients.
                    </p>
                    <p className="uk-margin-large-bottom box-text3">Our presence are in Cochin (Head office) and Pan India (Associates).
                    </p>
                    </div>
                    <div className="col-md-6 uk-margin-large-bottom">
                        <img className="" style={{width:'324px'}} src={Team} alt="" />
                    </div>
                </div>
                </div>
            </div>

        </div>
    )
}

export default Firm
