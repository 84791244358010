import React from 'react'
import StepBanner from '../StepBanner/StepBanner'
import '../ProccessOfRecruit/RecProcess.css'
import ContentData from '../ProccessOfRecruit/RecProcess.json'
import OurContact from '../../Recruiters/OurContact/OurContact'
function RecProcess() {
    return (
        <div>
            <StepBanner />
            <div className="container uk-container-xsmall ">
                {ContentData.map(obj => {
                    return (


                        <div className="row uk-margin-large-top">
                            <div className="col-md-2 col-2">
                                <p className="numb uk-text-right">{obj.number}</p>
                            </div>
                            <div className="col-md-10 col-10 uk-text-left">
                                <p className="numb-data uk-margin-top uk-margin-remove-bottom">{obj.stage}</p>
                                <p className="numb-data2 uk-margin-remove-top">{obj.title}</p>
                                <p className="numb-para">{obj.data}</p>
                                <p className="numb-para">{obj.data2}</p>
                                <p className="numb-para">{obj.data3}</p>
                                <p className="numb-para">{obj.data4}</p>
                                <p className="numb-para">{obj.data5}</p>
                                <p className="numb-para">{obj.data6}</p>
                                <p className="numb-para">{obj.data7}</p>

                            </div>
                        </div>

                    )
                })}

            </div>
            <OurContact />
        </div>
    )
}

export default RecProcess
