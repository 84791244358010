import React from 'react'
import '../OurContact/OurContact.css'
import Firm from '../Firm/Firm'
import Team2 from '../../Components/Assets/ContactMe.png'
import RecFooter from '../RecFooter/RecFooter'
function OurContact() {
    return (
        <div>
            {/* <Firm/> */}
            <div className="container uk-visible@m" id='contactss'>
                <p className=" uk-margin-large-top title">Our Contact</p>
                <div className="row uk-margin-small-top ross">
                    <div className="col-md-6">
                        <img src={Team2} alt="" />
                    </div>
                    <div className="col-md-6">
                        <p className="provider">You may get in touch with our team in the numbers
                            indicated below to know more about what we can offer.
                        </p>
                        {/* <i class="fa fa-phone  fa-flip-horizontal" aria-hidden="true"></i> <a className="atag uk-margin-left" href="tel:+91 97452 88782"> +91 97452 88782 </a> */}
                        <br />
                        <span uk-icon="whatsapp"></span> <a className="atag uk-margin-left" href='https://wa.me/ +91 9446345626'> +91 9567 183 183 </a>
                        <br />
                        <span uk-icon="mail"></span> <a className="atag uk-margin-left" href='mailto:hello@recruitmagik.com'> hello@recruitmagik.com </a>
                        <br />
                        {/* <span uk-icon="world"></span> <a className="atag uk-margin-left" href='http://eguidancelabs.com/'> www.eguidancelabs.com </a> */}
                    </div>
                </div>
            </div>


            <div className="container uk-hidden@m" id='contactz'>
                <p className=" uk-margin-large-top title">Our Contact</p>
                <div className="row uk-margin-large-top">

                    <div className="col-md-6">
                        <p className="provider">You may get in touch with our team in the numbers
                            indicated below to know more about what we can offer.
                        </p>
                        {/* <i class="fa fa-phone  fa-flip-horizontal" aria-hidden="true"></i> <a className="atag uk-margin-left" href="tel:+91 97452 88782"> +91 97452 88782 </a> */}
                        <br />
                        <span uk-icon="whatsapp"></span> <a className="atag uk-margin-left" href='https://wa.me/ +91 9446345626'> +91 9567 183 183 </a>
                        <br />
                        <span uk-icon="mail"></span> <a className="atag uk-margin-left" href='mailto:hello@recruitmagik.com'> hello@recruitmagik.com</a>
                
                        {/* <span uk-icon="world"></span> <a className="atag uk-margin-left" href='http://eguidancelabs.com/'> www.eguidancelabs.com </a> */}
                    </div>
                    <div className="col-md-6 uk-margin-small-top uk-margin-xlarge-bottom">
                        <img src={Team2} alt="" />
                    </div>
                </div>
            </div>
            {/* <RecFooter/> */}
        </div>
    )
}

export default OurContact
