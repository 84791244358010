import React from "react";
import TeamMembers from "../Tems/Team.json";
import ".././Client/Client.css";
function Teams() {
  return (
    <div className="container">
      <p className="uk-margin-large-top title">Some of Our Recruiters</p>
      <div className=" flexo" >
        {TeamMembers.map((obj) => {
          return (
            <div className="uk-margin-left">
              <img className=" imgTeam" src={obj.images} alt="" />
              <h4 className=" desig">{obj.name}</h4>
              <h4 className=" desig">{obj.designation}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Teams;
