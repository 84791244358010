import React from "react";
import Logo from "../../Components/Assets/recruitmagik.svg";
import "../HeadRec/Head.css";
import Home from "../../Components/Assets/home.png";
import Close from "../../Components/Assets/closexs.png";
import { Link, Router } from "react-router-dom";
function Head() {
  const scrollNow = () => {
    if (window.location.pathname === "/") {
      window.scrollTo(0, 0);
    }
  };

  const scrollbelow = () => {
    window.location.href = "/#client";
  };
  const scrollContact = () => {
    if (window.location.pathname === "/jobpost") {
      window.location.href = "/#contactss";
    }
  };

  return (
    <div className="">
      {window.innerWidth < 750 ? (
        <div className="fleix">
          <div className="">
            {" "}
            <Link to="/">
              <img onClick={scrollNow} className="nav-img " src={Logo} alt="" />
            </Link>
          </div>
          <div>
            <Link
              to="/empanelment"
              onClick={() => scrollbelow(window.location.pathname)}
            >
              <button class="uk-button emp " type="button">
                <span>
                  <p>Empanel with us</p>
                </span>
              </button>
            </Link>
            <button
              class="uk-button menubox"
              type="button"
              uk-toggle="target: #offcanvas-flip"
            >
              <span uk-icon="menu"></span>
            </button>
            <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
              <div class="uk-offcanvas-bar bg">
                <button
                  class="uk-offcanvas-close uk-button cl"
                  type="button"
                  uk-close="true"
                >
                  <img src={Close} alt="" />
                </button>
                <ul class="uk-list">
                  {window.location.pathname != "/" && (
                    <li>
                      <a href="/" className="nav-text">
                        Home
                      </a>
                    </li>
                  )}

                  <li>
                    {" "}
                    <a
                      onClick={() => scrollbelow(window.location.pathname)}
                      className="uk-margin-medium-right nav-text bgli"
                      href="#client"
                    >
                      Clients
                    </a>
                  </li>

                  <li>
                    <a href="/process" className="nav-text">
                      Recruitment Process
                    </a>
                  </li>
                  <li>
                    <a href="/jobpost" className="nav-text ">
                      Jobs
                    </a>
                  </li>
                  {/* <li>
                    <a
                      onClick={() => scrollContact(window.location.pathname)}
                      href="#contactss"
                      className="nav-text"
                    >
                      {" "}
                      Contact Us
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <nav class="navbar navbar-expand-lg navbars navBar ">
          <Link to="/">
            <img onClick={scrollNow} className="nav-img " src={Logo} alt="" />
          </Link>

          <div className="uk-navbar-right uk-visible@m">
            <a
              onClick={() => scrollbelow(window.location.pathname)}
              className="uk-margin-medium-right nav-text"
              href="#client"
            >
              Clients
            </a>

            <Link to="/process" className="nav-text">
              Our Recruitment Process
            </Link>
            <Link
              to="/jobpost/Java"
              className="nav-text uk-margin-medium-left "
            >
              Jobs
            </Link>
            {/* <Link to="/empanelment">
              <button class="uk-button emp  uk-margin-medium-left" type="button">
                <span>
                  <p>Empanel with us</p>
                </span>
              </button>
            </Link> */}
            <Link
              to="/empanelment"
              onClick={() => scrollbelow(window.location.pathname)}
            >
              <button
                class="uk-button emp  uk-margin-medium-left"
                type="button"
              >
                <span>
                  <p>Empanel with us</p>
                </span>
              </button>
            </Link>
            <a
              onClick={() => scrollContact(window.location.pathname)}
              href="#contactss"
              className="nav-text uk-margin-medium-left uk-margin-medium-right"
            >
              {" "}
              {/* Contact Us */}
            </a>
          </div>
        </nav>
      )}
    </div>
  );
}

export default Head;
