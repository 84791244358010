import React from 'react'
import '../Pages/Startup.css'
import BG from '../Assets/bg.png'

function Startup() {
    return (
        <div className="">
        <div className="banner uk-visible@m">
            <div className="container ">
                <div className="row uk-margin-xlarge-bottom">
                    <div className="col-md-6 uk-margin-xlarge-top">
                        <p className="process-text-1 uk-margin-xlarge-top uk-margin-xlarge-bottom">Build your Startup ideas with us</p>
                       </div>
                    <div className="col-md-6">
                    <div className="uk-margin-xlarge-top uk-margin-xlarge-bottom">
                  <img src={BG} alt="there is a photo" />  
                   </div>
                    </div>
                </div>
            </div>
        </div>

{/* small screen */}

<div className="banner uk-hidden@m">
            <div className="container ">
                <div className="row uk-margin-xlarge-bottom">
                    <div className="col-md-6 uk-margin-xlarge-top">
                    <p className="process-text-1 uk-margin-xlarge-top ">Build your Startup ideas with us</p>

                    <div className="uk-margin-xlarge-top ">
                  <img src={BG} alt="" />  
                   </div>
                    </div>
                   
                </div>
            </div>
        </div>

<div className="container">
<h1 className="head">Process</h1>

<div class="uk-grid-small uk-child-width-expand@s uk-text-center uk-margin-large-top" uk-grid="true">
    <div>
    
        <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1512314889357-e157c22f938d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aWRlYXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">1</b><p className="procard-text uk-text-emphasis  uk-text-left"> Ideate</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">We convert your thoughts and business ideas into beauitiful practical concepts</p>
        </div>
    </div>
    <div>
    <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cmVzZWFyY2h8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">2</b><p className="procard-text uk-text-emphasis"> Market Research</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">Conducting market research is the first step to determine if you really do have an idea worth pursuing</p>
        </div>
        </div>
    <div>
    <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJyYW5kaW5nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">3</b><p className="procard-text uk-text-emphasis  uk-text-left"> Branding</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">Branding is about more than just choosing a name, it's about deciding on an identity for your idea</p>
        </div>
    </div>
</div>

<div class="uk-grid-small uk-child-width-expand@s uk-text-center uk-margin-large-top" uk-grid="true">
    <div>
    
        <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y29ycG9yYXRlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">4</b><p className="procard-text uk-text-emphasis  uk-text-left"> Incoprate</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">Incorporating is a big deal for a startup because there are so many aspect of the startup lifecycle that affect it, and are affected by it</p>
        </div>
    </div>
    <div>
    <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1573165662973-4ab3cf3d3508?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YnVzc2luZXN8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">5</b><p className="procard-text uk-text-emphasis"> Business Plan</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">Using the market research you did earlier, create your plan of attack and decide what you want to accomplish with your new business</p>
        </div>
        </div>
    <div>
    <div class="uk-card  uk-card-body ">
        <img src="https://images.unsplash.com/photo-1551836022-d5d88e9218df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGludGVydmlld3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="" />
        <b className="pro-num uk-align-left uk-margin-remove-vertical">6</b><p className="procard-text uk-text-emphasis  uk-text-left">Recruiting</p>
      <p className="uk-text-emphasis uk-align-left uk-text-left">Where you work actually does have an effect on your startup as you're getting off the ground</p>
        </div>
    </div>
</div>
{/* <button class="uk-button btn1 uk-button-large uk-margin-large-top uk-margin-left">Switch Now</button> */}
</div>

    </div>
    )
}

export default Startup
