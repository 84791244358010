import React from "react";
import "../Footer/Footer.css";

function Footer({ name }) {
  return (
    <div class="uk-margin-medium-top  uk-grid footer-set uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-child-width-1-3@xl  ">
      <div class="">
        <div class="uk-margin-bottom">
          <h3 class="uk-card-title">{name}</h3>
          <p>
            1st Floor ,KC tower,
            <br />
            Padivattom Road Near Oberon Mall,
            <br />
            Padivattom,Edappally ,Kochi ,Kerala 682030 <br />
            <a
              style={{ color: "black" }}
              href="mailto:hello@recruitmagik.com ?subject = Feedback&body = Message"
            >
              <i class="fa fa-envelope"></i> hello@recruitmagik.com
            </a>
            <br />
            <a style={{ color: "black" }} href="tel:9567183183">
             Hello @ : 9567 183 183
            </a>
          </p>
        </div>
      </div>

      {/* <div className=" icons">
            <a href="" class="  uk-align-right" uk-icon="mail"></a>
            <a href="" class="  uk-align-right" uk-icon="instagram"></a>
            <a href="" class="uk-align-right" uk-icon="facebook"></a>
        </div> */}
    </div>
  );
}

export default Footer;
