import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Description/Description.css";
import { Link, withRouter, useHistory, Route } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import Model from "../Model/Model";
import ListJson from "../List/List.json";

function Description(props) {

  // const [jobdata, setJobdata] = useState();
  // const [jobdata, setjobdata] = useState(ListJson[0])
  const jobdata = ListJson.find(
    (el) => el.titleId == props.match.params.titleId
  );

console.log(jobdata);
  const history = useHistory();

  function handleClick() {
    history.push("/jobpost");
  }

  return (
    <div>
      <div className="uk-margin-large-left uk-margin-xlarge-top ppding">
        <p className="company"></p>
        <div onClick={handleClick} className="backto">
          <a href="" uk-icon="icon: chevron-left"></a>
        </div>

        <p className="position">{jobdata?.job_title}</p>
        {jobdata.id && (
          <div className="flei margos">
            <a

              onClick={() => window.open(jobdata?.apply_URL)}
            >
              <button class="uk-button btn_one">Apply now</button>
            </a>
            {/* <button onClick={handleClick} class="uk-button uk-margin-left btn_two ">Save job</button> */}
          </div>
        )}
        <p className="loc">{jobdata?.location}</p>
        {/* <p className="details"> {jobdata.job_description}</p> */}
        {/* <div className="flei margos">
        <Link to="/submit">
          <button class="uk-button btn_one">Apply now</button>
        </Link>
        <button class="uk-button uk-margin-left btn_two ">Save job</button>
      </div> */}

        {jobdata.job_description && (
          <div>
            <p className="hed uk-text-bold uk-text-left ">Job Description</p>
            {/* <p className="details">{jobdata.job_description} </p> */}
            <div className="details" dangerouslySetInnerHTML={{ __html: jobdata.job_description }} />
          </div>
        )}
        {jobdata.Work_mode && (
          <div className="">
            <p className="hed uk-text-bold  uk-text-left uk-margin-medium-top">
              Work mode - {jobdata.Work_mode}
            </p>
          </div>
        )}
        {jobdata.experience && (
          <div className="">
            <p className="hed uk-text-bold  uk-text-left uk-margin-medium-top">
              Experience - {jobdata.experience}
            </p>
          </div>
        )}

        {jobdata.Technical_skills && (
          <div className="">
            <p className="hed uk-text-bold  uk-text-left uk-margin-medium-top">
              Technical Skills
            </p>
            <p className="details"> {jobdata.Technical_skills}</p>
          </div>
        )}
        {jobdata.responsblities && (
          <div className="">
            <p className="hed uk-text-bold  uk-text-left uk-margin-medium-top">
              Responsiblities
            </p>
            <ul class="">
              {jobdata.responsblities.map((objs) => {
                return (
                  <li>
                    <p className="details"> {objs.points}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {jobdata.requirement && (
          <div>
            <p className="uk-text-left hed uk-text-bold uk-margin-medium-top ">
              Requirement
            </p>
            <ul class="">
              {jobdata.requirement.map((objs) => {
                return (
                  <li>
                    <p className="details"> {objs.points}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Description);
