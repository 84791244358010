import React from 'react'
import '../Block/Block.css'
function Block({name}) {
    return (
        <div>
            {/* <Service/> */}
     
            <div className="" style={{backgroundColor:"#4168AC"}}>

            
            <div className="container uk-margin-xlarge-top " >
               <b>.</b>
            <p className="  block-title uk-margin-large-top " >Why {name}?</p>
            <div className="uk-margin-large-top">


            <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center uk-grid-column-collapse  uk-grid-row-collapse" uk-grid="masonry: true" >
    <div className="uk-margin-remove-bottom ">
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-bottom">
            <p className="box-text">Good Network to manage the Mock Interviews in IT sector</p>
        </div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-bottom">
        <p className="box-text">AI Based Recruitment Solutions will be provided soon</p>
        </div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-bottom">
        <p className="box-text">Highly Experienced Recruitment Team who work with both offshore and Onsite candidates</p>
        </div>
    </div>
     <div>
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-top ">
        <p className="box-text">Free Technical Screening with the predefined Questionnaire for all cutting edge Technologies</p>
        </div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-top ">
        <p className="box-text">Client Satisfaction oriented services</p>
        </div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body cardbg uk-margin-remove-top uk-margin-xlarge-bottom">
        <p className="box-text">Lead by veterans in IT Industry</p>
        </div>
    </div> 
</div>


            </div>
            
            
            </div>
            </div>
        </div>
    )
}

export default Block
