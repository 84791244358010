import React from 'react'
import '../Cover/Cover.css'
import Illustration from '../../Components/Assets/Banner_Image.png'
import FinelOutput from '../FinelOutput/Finel'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";



function Cover({ name }) {
    // const toRec=()=>{
    //     window.open('/rec');
    // }
    return (
        <div>
            {/* <Head/> */}
            <div className="uk-visible@m">
                <div className="banner3">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-md-7 uk-margin-medium-top banner-one  uk-margin-large-bottom">
                                <p className="title1 uk-margin-xlarge-top">Filling the Gap between Experts and Recruiters </p>
                                <p className="title-para">We know how hard it is for the tech organizations to find the right fit for their open roles.
                                    {" "+name} is here to help you with a pragmatic solution to fill up the tech positions.
                                    We are able to absolve you from this as we have an unbiased , inclusive  technical interviewing service with the industry’s best Talent Acquisition  team with more than 18+years of experience.</p>
                                <div className="uk-margin-large-top">

                                    <Link to='/process' href=""><button class="uk-button-large btn uk-button-default "><p className="uk-margin-top btn-txt">Read More</p></button></Link>

                                </div>
                            </div>
                            <div className="col-md-5 uk-margin-xlarge-top">
                                <div className=" uk-margin-xlarge-bottom ">

                                    <img className="banner-img " src={Illustration} alt="there is a photo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            {/* small-screen */}

            <div className="uk-hidden@m">
                <div className="banner3">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-md-7 uk-margin-medium-top banner-one uk-margin-xlarge-top  uk-margin-large-bottom">
                                <p className="title1 ">Filling the Gap between Experts and Recruiters </p>
                                <p className="title-para">We know how hard it is for the tech organizations to find the right fit for their open roles. {name} is here to help you with a pragmatic solution to fill up the tech positions.
                                    We are able to absolve you from this as we have an unbiased , inclusive  technical interviewing service with the industry’s best Talent Acquisition  team with more than 18+years of experience.</p>
                                <div className="uk-margin-large-top">
                                    <Link to='/process' href=""><button class="uk-button-large btn uk-button-default "><p className="uk-margin-top btn-txt">Read More</p></button></Link>
                                </div>
                            </div>
                            <div className="col-md-5 ">
                                <div className=" " style={{width:'270px'}}>

                                    <img className=" uk-align-center"  src={Illustration} alt="there is a photo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Cover
