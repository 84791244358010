import React, { useState, useEffect, useRef } from "react";
import "../List//List.css";
import ListJson from "../List/List.json";
import Description from "../Description/Description";
import Footer from "../Footer/Footer";
import User from "../Description/User";
import Model from "../../Components/Model/Model";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

function List({ dataPass, showDefaultJob, colour, setColur }) {
  // const dataPass = (e) => {
  //   setcompany(e);
  // };

  const [active, setActive] = useState( window.location.pathname.split("/")[2]);
  const [url, setUrl] = useState();
  const urlFetcher = (e) => {
    // e.preventDefault()
    setUrl(e);
  };

  const [bg, setBg] = useState();
  const inputEl = useRef();
  // const onButtonClicker = () => {
  //  setBg(inputEl.current.style.backgroundColor = 'red')
  // };

  const handleClick = (obj) => {
    dataPass(obj);
   setActive(obj.titleId);
  };

  return (
    <>
      <div>
        <div className=" uk-container uk-container-medium uk-container marginal">
          <div class="uk-text-center" uk-grid="true">
            <div class="uk-list flei">
              <div class="">
                {ListJson.map((obj, id) => {
                  return (
                    <div
                      key={obj.id}
                      class={
                        active == obj.titleId
                          ? "sidebar-item-active"
                          : ""
                      }
                    >
                      {window.innerWidth < 780 ? (
                        <Link to={"/jobposts/" + obj.titleId}>
                          <div
                            class="panalis uk-margin-top"
                            onClick={() => handleClick(obj)}
                          >
                            <img
                              class="uk-align-left  imgwid"
                              src={obj?.Log}
                              alt="Example image"
                            />
                            <div>
                              {/* <p className="first uk-text-left uk-margin-remove-bottom ">
                                {obj.company}
                              </p> */}
                              <p className="second uk-text-left uk-margin-top ">
                                {obj.job_title}
                              </p>
                              {/* <p className="third uk-text-left uk-margin-remove-bottom uk-margin-remove-top">
                                {obj.location}
                              </p> */}
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link to={"/jobpost/" + obj?.titleId}>
                          <div
                            class="panalis"
                            id={"jobMenu" + obj.titleId}
                            onClick={() => handleClick(obj)}
                          >
                            <img
                              class="uk-align-left  imgwid"
                              src={obj?.Log}
                              alt="Example image"
                            />
                            <div>
                              <p className="first uk-text-left  ">
                                {obj?.company}
                              </p>
                              <p className="second uk-text-left uk-margin-remove-bottom ">
                                {obj.job_title}
                              </p>
                              <p className="third uk-text-left uk-margin-remove-bottom uk-margin-remove-top">
                                {obj?.location}
                              </p>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
              <div class="uk-width-3-5 disspl">
                <div className="vl ">
                  {/* <Route path="/jobposts/:titleId" children={<Description urlFetcher={urlFetcher} />} /> */}
                </div>
              </div>
            </div>
            {/* <Route path="/submit" >
              <Model url={url} />
            </Route> */}
            <div class="uk-width-3-5 ">
              {/* <div className="v">
              {ListJson.map((obj, id) => {
                return <Description companyName={obj.company} />;
              })}
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
