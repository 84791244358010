import React, { useState } from "react";
import "../TechWeDo/TechWeDO.css";
import TechWeDoJson from "../TechWeDo/Techwedo.json"
function TechWeDo() {
  const [datas, setdata] = useState()
  return (
    <div className="common_width">
      <p className="headingTech">Technologies We Recruit</p>
      {TechWeDoJson.map((obj, index) => {
        return (
          <div key={index} className="flexi">
            <p className="sectionz">{obj.Department}</p>
            <div className="gridSys">
              {obj.TechData.map(techdataobj => {
                return (
                  <div className="boxTech">
                    <img src={techdataobj.Log} alt="" />
                    <p className="boxTechTxt">{techdataobj.Tech}</p>
                  </div>
                )
              })}

            </div>
          </div>)
      })}
    </div>
  );
}

export default TechWeDo;
