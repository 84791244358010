import React from 'react'
import '../StepBanner/StepBanner.css'
function StepBanner() {
    return (
        <div className="" style={{backgroundColor:"black"}}>
            <b>.</b>
            <div className=" container ">
                <p className="t-one uk-text-center uk-margin-xlarge-top uk-margin-large-bottom">Recruitment Process Document</p>
            </div>
            <b className="">.</b>
        </div>
    )
}

export default StepBanner
