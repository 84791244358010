import React from 'react'

function Empanelment() {
    return (
        <div className=" marginal">
           <iframe
          src="https://forms.gle/4DSRPkH379fuu7kj7"

          width="100%"
          height="1000"
          frameborder="0"
          marginheight="0"
          marginwidth="0">
          Loading…
        </iframe>
        </div>
    )
}

export default Empanelment